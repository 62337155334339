<script>
    import BaseAnimation from '../BaseAnimation';

    export default {
        name: 'stagger-fade-horizontal-animation',

        mixins: [BaseAnimation],

        props: {
            trigger: {
                type: Function,
                required: true,
            },

            elements: {
                type: Array,
                required: true,
            },

            reverse: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            /**
             * Get the elements that will animate.
             *
             * @returns {object}
             */
            batchElements() {
                return this.elements.map(({ element }) => {
                    const el = element();

                    if (el._isVue) {
                        return el.$el;
                    }

                    return el;
                });
            },
        },

        beforeDestroy() {
            if (this.motionAccepted) {
                [...this.batchElements].forEach((_, i) => {
                    if (this.scrollTrigger.getById(`${ this.uid }-${ i }`)) {
                        this.scrollTrigger.getById(`${ this.uid }-${ i }`).kill();
                    }
                });
            }
        },

        methods: {
            /**
             * Set the initial state of the animated element.
             */
            setInitialStates() {
                [...this.batchElements].forEach((el) => {
                    el.style.opacity = 0;
                });
            },

            /**
             * Reveal animation.
             */
            reveal() {
                [...this.batchElements].forEach((el, i) => {
                    let xFrom = 100;

                    if (this.reverse) {
                        xFrom = -xFrom;
                    }

                    this.gsap.set(el, {
                        opacity: 0,
                        x: xFrom * (i + 1),
                    });
                });

                this.scrollTrigger.batch(this.batchElements, {
                    onEnter: (elements) => {
                        let triggerEl = this.trigger();

                        if (triggerEl?._isVue) {
                            triggerEl = triggerEl.$el;
                        }

                        let els = elements;
                        let xFrom = 100;

                        if (this.reverse) {
                            els = elements.concat().reverse();
                            xFrom = -xFrom;
                        }

                        els.forEach((item, i) => {
                            this.gsap.fromTo(
                                item,
                                {
                                    opacity: 0,
                                    x: xFrom * (i + 1),
                                },
                                {
                                    opacity: 1,
                                    x: 0,
                                    stagger: 2,
                                    scrollTrigger: {
                                        trigger: triggerEl,
                                        id: `${ this.uid }-${ i }`,
                                        start: 'top 90%',
                                    },
                                },
                            );
                        });
                    },
                    once: true,
                });
            },
        },
    };
</script>
